import css from "PFApp/activities/show/activity_page/activity_page.module.scss";

import { MatchesConstraints } from "./matches_constraints";
import { MatchesInformation } from "./matches_information";

export const MatchesSidePanel = () => (
  <div className={css.pageCardSidePanel}>
    <MatchesInformation />
    <MatchesConstraints />
  </div>
);
