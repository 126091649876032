import isNil from "lodash/isNil";
import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { BookingFormMode } from "PFApp/booking/components/booking_form/use_booking_form";
import { OnSuccessData } from "PFApp/booking/components/booking_form/use_handle_submit/use_handle_submit";
import BookingDeleteButton from "PFApp/booking/components/delete_booking_modal/booking_delete_button";
import { DeleteMode } from "PFApp/booking/components/delete_booking_modal/delete_booking.types";
import { useDetailsPanelApiContext } from "PFApp/booking/components/details_panel";
import { useBookingsOverviewContext } from "PFApp/booking/parts/overview/context/bookings_overview_context";
import { TemplateKey } from "PFApp/constants/templates";
import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { useBooking } from "PFCore/hooks/queries";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking } from "PFTypes/booking";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTemplate } from "../../../../../../hooks";
import { useBookingActivityContext } from "../../../../providers/booking_activity_context_provider";
import css from "../../detail_view.module.scss";
import { useBookingDetailSidePanelContext } from "../booking_detail_side_panel_context";
import { ReassignButton } from "./reassign_button";

export const getDeleteMode = (activityId: number | null, activitySiblingsCount: number): DeleteMode => {
  if (!activityId) {
    return DeleteMode.NoActivityBooking;
  }
  if (activitySiblingsCount > 0) {
    return DeleteMode.MultipleBookings;
  }
  return DeleteMode.LastBooking;
};

interface ButtonsProps {
  profileFullName: string;
  onDeleted?: (booking: Booking) => Promise<void>;
  onEdit: (data: OnSuccessData) => void;
  reassignSidePanelZIndex: number | undefined;
}

const Buttons = ({ profileFullName, onDeleted, onEdit, reassignSidePanelZIndex }: ButtonsProps) => {
  const { modal } = useBookingFormContext();
  const {
    calendarData: { data }
  } = useBookingsOverviewContext();
  const { closeDetailsPanel } = useDetailsPanelApiContext();
  const { utc } = useDateFormatter();
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { siblingBookings, currentSiblingBookingIndex, bookingTemplate, isBookingTemplateFetching } =
    useBookingDetailSidePanelContext();

  const { activity } = useBookingActivityContext();
  const template = useTemplate(activity?.template_id);
  const isEngagement =
    !!template && [TemplateKey.Engagement, TemplateKey.AuditEngagement].includes(template.key);

  const currentSiblingBooking = !isNil(currentSiblingBookingIndex)
    ? siblingBookings?.[currentSiblingBookingIndex]
    : undefined;

  const { getCategory } = useBookingCategories();
  const isCategoryHidden = currentSiblingBooking ? !getCategory(currentSiblingBooking) : false;
  // buttons need to fetch full booking details to check source and readonly
  const { data: booking, isFetching: isBookingFetching } = useBooking(currentSiblingBooking?.id, {
    enabled: !!currentSiblingBooking?.id && !isCategoryHidden
  });

  const isSingleDayBookingTemplate = useMemo(
    () => bookingTemplate && utc(bookingTemplate.end_date).diff(bookingTemplate.start_date, "days") === 0,
    [bookingTemplate, utc]
  );

  if (isBookingFetching) {
    return <LoadingDots centered circlesEnabled circleSize="xs" className={css.buttonsLoader} />;
  } else if (!booking || isCategoryHidden) {
    return null;
  }

  const {
    id,
    booking_template_id: bookingTemplateId,
    activity_siblings_count, // eslint-disable-line camelcase
    source,
    readonly
  } = booking || {};

  const handleEdit = () => modal.open({ bookingId: id }, { onSuccess: onEdit, mode: BookingFormMode.Edit });

  const handleClone = () =>
    modal.open(
      {
        bookingId: id,
        ...(data.meta.search_id
          ? {
              groupBookingData: {
                searchId: data.meta.search_id,
                total: data.meta.total
              }
            }
          : {})
      },
      { onSuccess: onEdit, mode: BookingFormMode.Clone }
    );

  const deleteMode = getDeleteMode(booking.activity_id ?? null, activity_siblings_count);
  const cloneEnabled = !booking?.booking_template_id;

  const handleDelete = (entity: Booking) => {
    closeDetailsPanel();
    return onDeleted ? onDeleted(entity) : Promise.resolve();
  };

  return (
    <div className={css.footer}>
      {source === "ui" ? (
        !readonly && (
          <div className={css.buttons}>
            <Button
              icon="edit"
              onClick={handleEdit}
              kind="secondary"
              disabled={Number.isInteger(bookingTemplateId) && isBookingTemplateFetching}
            />
            {cloneEnabled && <Button icon="duplicate" onClick={handleClone} kind="secondary" />}
            {booking.activity_id && (
              <ReassignButton
                booking={booking}
                activity={activity}
                isEngagement={isEngagement}
                availability={activity?.availability}
                sidePanelZIndex={reassignSidePanelZIndex}
              />
            )}
            <BookingDeleteButton
              deleteMode={deleteMode}
              booking={booking}
              profileFullName={profileFullName}
              onDeleted={handleDelete}
              isSingleDayBookingTemplate={isSingleDayBookingTemplate}
            />
          </div>
        )
      ) : (
        <Alert title={t("bookingModule:bookings.delete.externalSourceWarning")} hideIcon small />
      )}
    </div>
  );
};

export default Buttons;
