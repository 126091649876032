import { isNil } from "lodash";
import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { BookingFormMode } from "PFApp/booking/components/booking_form/use_booking_form";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistFillAndBook } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { Activity, Id } from "PFTypes";
import { useCallback } from "react";

type HandleFillAndBookParams = {
  activity: Pick<Activity, "id" | "parent_activity_id">;
  profileId: Id;
  shortlistId?: Id;
  vacancyId?: Id;
};

export const useFillAndBookAction = () => {
  const { modal } = useBookingFormContext();
  const growlError = useErrorsGrowl();

  const { mutateAsync: fillAndBook, isLoading: isFillAndBooking } = useShortlistFillAndBook();
  const { mutateAsync: createShortlist, isLoading: isCreatingShortlist } = useShortlistCreate();
  const { invalidateVacancies } = useActivityInvalidate();

  const handleCustomFillAndBook = ({
    activity,
    profileId,
    shortlistId,
    vacancyId
  }: HandleFillAndBookParams) => {
    modal.open(
      {
        profileId: Number(profileId),
        activityId: activity.id
      },
      {
        mode: BookingFormMode.Create,
        onSuccess: () => {
          handleFillAndBook({ activity, profileId, shortlistId, vacancyId }, true);
        }
      }
    );
  };

  const handleFillAndBook = useCallback(
    async (
      { activity, profileId, shortlistId, vacancyId }: HandleFillAndBookParams,
      skipBookingsCreation: boolean = false
    ) => {
      try {
        const finalShortlistId =
          shortlistId || (await createShortlist({ activityId: activity.id, profileId })).id;

        const response = await fillAndBook({
          shortlistId: finalShortlistId,
          skipBookingsCreation,
          task: activity,
          profileId: Number(profileId),
          ...(!isNil(vacancyId) ? { vacancyId: Number(vacancyId) } : {})
        });

        await invalidateVacancies(activity.id);

        return response;
      } catch ({ response }) {
        growlError(response);
      }
    },
    [createShortlist, fillAndBook, growlError, invalidateVacancies]
  );

  const isLoading = isFillAndBooking || isCreatingShortlist;

  return {
    isLoading,
    handleFillAndBook,
    handleCustomFillAndBook
  };
};
