import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityTemplate } from "PFApp/activities/show/hooks/use_activity_template";
import { useFiltersPanelContext } from "PFApp/components/filters/context/filters_panel_context";
import { AvailabilityFilterItemCompressed } from "PFApp/components/filters/filter_items";
import { useAvailabilityState } from "PFApp/components/filters/filter_items/availability_filter";
import { getAPIFilters, getValueFilters, handleRequestFiltersChange } from "PFApp/use_filtered_collection";
import { letFilterRestore } from "PFApp/use_filtered_collection";
import { Value } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { SidePanelHeader } from "../../../../components/side_panel";
import { useAvailabilityFilter } from "../hooks/use_availability_filter";

export const MatchesAvailability = () => {
  const { matchesMeta, updateMatchesParams } = useActivityPageContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.sidePanel" });
  const template = useActivityTemplate();
  const { areFiltersDisabled } = useFiltersPanelContext();

  const valueFilters = getValueFilters(matchesMeta.filters);
  const scope = (matchesMeta?.counters?.filtered_profiles_count as number) || 0;

  const { availabilityFilter, isAvailabilityFilterOn } = useAvailabilityFilter();

  const handleFilterChange = useCallback(
    (value: Partial<Value>) => {
      const newFilters = handleRequestFiltersChange(valueFilters || {}, availabilityFilter, value);
      updateMatchesParams({ filters: getAPIFilters(newFilters), page: 1 });
    },
    [availabilityFilter, updateMatchesParams, valueFilters]
  );

  const { restoreAvailability } = useAvailabilityState(availabilityFilter, handleFilterChange);

  const clearAvailability = useCallback(() => {
    handleFilterChange(null);
  }, [handleFilterChange]);

  if (
    areFiltersDisabled ||
    !template ||
    (!availabilityFilter?.value && !(availabilityFilter && letFilterRestore(availabilityFilter)))
  ) {
    return null;
  }

  const handleAvailabilityToggle = () => {
    if (isAvailabilityFilterOn) {
      clearAvailability();
    } else {
      restoreAvailability();
    }
  };

  return (
    <div>
      <SidePanelHeader
        icon="calendar"
        title={
          isAvailabilityFilterOn
            ? t("availability.titleWithCounter", {
                count: matchesMeta.distribution?.counters.prefiltered_and_available?.total || 0
              })
            : t("availability.title")
        }
        checked={isAvailabilityFilterOn}
        onToggle={handleAvailabilityToggle}
        qaId="ActivityMatchesTabAvailabilityToggle"
      />
      {isAvailabilityFilterOn && (
        <AvailabilityFilterItemCompressed
          filter={availabilityFilter}
          scope={scope}
          onChange={handleFilterChange}
          templateKey={template.key}
        />
      )}
    </div>
  );
};
