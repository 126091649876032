import classNames from "classnames";
import { isString } from "lodash";
import { IconName } from "PFTheme/graphics/icons";
import { Children } from "react";

import { ActionIcon } from "../action_icon";
import { Button, ButtonProps } from "../button";
import { Icon } from "../icon";
import { Typography } from "../typography";
import css from "./alert.module.scss";

const ICON_MAP: Record<string, IconName> = {
  error: "error",
  warning: "warning",
  success: "check",
  info: "info",
  general: "info"
};

type AlertProps = {
  children?: React.ReactNode;
  className?: string;
  hideIcon?: boolean;
  icon?: IconName;
  kind?: "error" | "warning" | "success" | "info" | "general";
  qaId?: string;
  small?: boolean;
  title?: string | React.ReactNode;
  multiLineTitle?: boolean;
  action?: {
    onClick: () => void;
    text: string;
    icon?: IconName;
    kind?: ButtonProps["kind"];
    disabled?: ButtonProps["disabled"];
  };
  /**
   * @deprecated Use className instead
   */
  style?: React.CSSProperties;
  onClose?: () => void;
};

export const Alert = ({
  children,
  className,
  hideIcon,
  icon,
  kind = "warning",
  qaId,
  small,
  title,
  multiLineTitle = false,
  style,
  action,
  onClose
}: AlertProps) => (
  <div
    style={style}
    data-qa-id={qaId}
    className={classNames(css.root, css[kind], { [css.small]: small }, className)}
  >
    {title && (
      <div className={css.title}>
        {!hideIcon && (
          <Icon
            className={classNames(css.alertIcon, {
              [css.alertIconButtonAlign]:
                action && (!action?.kind || ["text", "blank"].includes(action?.kind))
            })}
            size="md"
            name={icon || ICON_MAP[kind]}
          />
        )}
        {isString(title) ? (
          <Typography variant={children ? "bodyBold" : "bodyRegular"} noMargin clipOverflow={!multiLineTitle}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {action && <Button className={css.actionButton} kind="ghost" {...action} />}
        {onClose && (
          <ActionIcon classes={{ root: css.actionButton }} name="cross" onClick={onClose} size="sm" />
        )}
      </div>
    )}
    {Children.toArray(children)}
  </div>
);
