import css from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Tile } from "PFComponents/tile";

import { ActivityReviewSection } from "../../../../../../parts/review_section";
import { RoleInformation } from "../../../components/role_information";
import { ShortlistSideFilters } from "../shortlist_side_filters";

export const ShortlistSidePanel = () => {
  const { task } = useActivityPageContext();

  return (
    <div className={css.pageCardSidePanel}>
      <Tile paddingVariant="lg" selected>
        <RoleInformation role={task} />
      </Tile>

      <Tile paddingVariant="lg" light>
        <ShortlistSideFilters />
      </Tile>

      <ActivityReviewSection />
    </div>
  );
};
