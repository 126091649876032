import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import BookingPill from "PFApp/components/pills/booking_pill";
import { Accordion } from "PFComponents/accordion";
import { Typography } from "PFComponents/typography";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Activity, AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./role_information.module.scss";

type RoleInformationProps = {
  role: Activity;
};

export const RoleInformation = ({ role }: RoleInformationProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.roleDetails" });

  const { parentActivity } = useActivityPageContext();
  const { readableAvailability } = useReadableAvailability({
    availability: role.metadata.availability as AvailabilityRule
  });
  const { getBookingCategory } = useBookingCategories();

  const title = parentActivity ? `${parentActivity?.name} - ${role.name}` : role.name;

  const renderBookingCategory = () => {
    if (!role.booking_category_id) {
      return null;
    }

    const category = getBookingCategory(role.booking_category_id);

    return (
      <span className={css.bookingCategory}>
        <Typography variant="labelRegular" noMargin>
          {category?.display_as}
        </Typography>
        <BookingPill className={css.pill} category={category} />
      </span>
    );
  };

  return (
    <Accordion header={t("title")} defaultState="closed" headerSize="small" iconSet="chevron">
      <div className={css.root}>
        <Typography variant={"labelRegular"} noMargin>
          {title}
        </Typography>
        {readableAvailability && (
          <Typography variant={"labelRegular"} noMargin>
            {readableAvailability}
          </Typography>
        )}
        {renderBookingCategory()}
      </div>
    </Accordion>
  );
};
