import { isEmpty, isNil } from "lodash";
import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityMatchesFilters } from "PFApp/activities/show/hooks/use_activity_matches_filters";
import ActivityFilters from "PFApp/activities/show/parts/activity_filters";
import ActivityKeywords from "PFApp/activities/show/parts/activity_keywords";
import RefreshAction from "PFApp/activities/show/parts/refresh_action";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { useFiltersPanelContext } from "PFApp/components/filters/context/filters_panel_context";
import FiltersButton from "PFApp/components/filters/filters_button";
import { FiltersPanel, PagePrefix } from "PFApp/components/filters/filters_panel";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import { checkFiltersAreClear, getValueFilters, letFiltersRestore } from "PFApp/use_filtered_collection";
import { TableProperties, TablePropertiesButton } from "PFApp/workflow/parts/table_properties";
import { Alert } from "PFComponents/alert";
import { getTermFilterData } from "PFCore/helpers/get_term_filter_data";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { ProfilesViewMode } from "../../../../../../types/profiles_view_mode";
import { OrderSelector } from "../../components/order_selector";
import { ProfileViewModeToggle } from "../../components/profiles_view_mode_toggle";
import { useActivityPropertiesNames } from "../hooks/use_activity_properties_names";
import { useActivityPropertyFilters } from "./matches_constraints/hooks/use_activity_property_filters";
import css from "./matches_header.module.scss";

export const MatchesHeader = () => {
  const { t } = useTranslation(["activities", "translation"]);
  const {
    isFiltersPanelOpen,
    openedFilterTypes,
    openFiltersPanel,
    addOpenedFilterType,
    removeOpenedFilterType,
    activeFiltersAccordionIndex,
    setActiveFiltersAccordionIndex,
    areFiltersDisabled
  } = useFiltersPanelContext();
  const {
    task,
    matches,
    matchesFetchState,
    matchesMeta,
    availableMatchesShortlistColumns,
    selectedMatchesShortlistColumns,
    profilesViewMode,
    setSelectedMatchesShortlistColumns,
    updateMatchesParams,
    matchesReload
  } = useActivityPageContext();

  const handleChangeTypeOpen = (open: boolean, typeName: string) => {
    if (open) {
      addOpenedFilterType(typeName);
    } else {
      removeOpenedFilterType(typeName);
    }
  };

  const { id, coowned, state } = task || {};
  const { invalidateShortlists } = useShortlistsInvalidate();
  const [isTablePropertiesVisible, toggleIsTablePropertiesVisible] = useToggle(false);

  useEffect(() => {
    if (profilesViewMode === ProfilesViewMode.Cards) {
      toggleIsTablePropertiesVisible(false);
    }
  }, [profilesViewMode, toggleIsTablePropertiesVisible]);

  const valueFilters = getValueFilters(matchesMeta.filters);
  const filtersAreClear = checkFiltersAreClear(matchesMeta.filters);

  const schemaPropertiesNames = useActivityPropertiesNames();
  const { blockedFiltersList, excludedPropertyFilters } = useActivityPropertyFilters();

  const canShowFilters = matchesMeta?.filters;
  const isCompleted = state === "complete";

  const handleOrderChange = (value) => updateMatchesParams({ order: value, page: 1 });

  const { setFilters, resetFilters, updateFilter } = useActivityMatchesFilters();
  const letRestoreAll = letFiltersRestore(valueFilters, matchesMeta.default_filters);
  const filtersCounter = isEmpty(matchesMeta.filters) ? 0 : excludedPropertyFilters.length;

  const { termFilter, termValue, isTermFilterAvailable } = getTermFilterData(matchesMeta);

  const paginationLabel = useMemo(() => {
    const { page, perPage, total } = matchesMeta;

    if (total === 0) {
      return null;
    }
    const start = (page - 1) * perPage + 1;
    const end = Math.min(page * perPage, total);

    return t(
      `activities:show.matches.${
        filtersAreClear ? "matchesPaginationInfo" : "matchesPaginationInfoFiltered"
      }`,
      { start, end, count: total }
    );
  }, [filtersAreClear, matchesMeta, t]);

  return (
    <>
      <div className={css.container}>
        <SearchInput
          disabled={areFiltersDisabled || !isTermFilterAvailable}
          term={termValue}
          pageKey={RecentSearchesPageKey.ActivityMatches}
          autocompleteConfig={[
            {
              entity: SearchEntity.Profile,
              useAsTerm: true
            }
          ]}
          includeRecentSearches={false}
          classes={{ root: commonCss.searchInput }}
          onTermChange={(newTerm: string) => {
            updateFilter(termFilter!, newTerm);
          }}
          qaId="ActivityMatchesTabSearchInput"
        />
        <OrderSelector
          meta={matchesMeta}
          onOrderChange={handleOrderChange}
          showSelect={!isCompleted}
          label={paginationLabel}
          className={css.sortByBlock}
        />
        <ProfileViewModeToggle />
        {profilesViewMode === ProfilesViewMode.Table && (
          <TablePropertiesButton onClick={toggleIsTablePropertiesVisible} />
        )}
        {!isCompleted && canShowFilters && (
          <FiltersButton
            filtersAreClear={filtersAreClear}
            onClick={() => openFiltersPanel(!isFiltersPanelOpen)}
          />
        )}
        <LazyLocalExportTrigger
          quantity={matches?.length || 0}
          getProfilesCollection={() => {
            const items = (matches || []).map(({ profile }) => profile);
            return getExportProfiles({
              profileIds: items.map(({ id }) => id),
              activityId: id
            }).then((resp) => ({
              entries: resp.map((profile) => ({
                profile
              })),
              meta: { total: resp.length, page: 1 }
            }));
          }}
          buttonKind="secondary"
          icon="export"
          qaId="ActivityMatchesTabExportButton"
          tooltipContent={t("activities:show.iconsTooltips.export")}
        />
        {!isCompleted && coowned && (
          <RefreshAction
            activityId={id}
            onUpdate={() =>
              matchesReload &&
              matchesReload().then(() => {
                invalidateShortlists(id);
              })
            }
            showLabel={false}
            qaId="ActivityMatchesTabRefreshMatchesButton"
          />
        )}
      </div>
      {isFiltersPanelOpen && canShowFilters && (
        <FiltersPanel
          keyPrefix={PagePrefix.ActivityMatches}
          disabled={areFiltersDisabled}
          disabledLabel={t("activities:show.matches.filtersDisabled")}
          isLoading={matchesFetchState === "pending"}
          closeFilters={() => openFiltersPanel(false)}
          filtersAreClear={filtersAreClear}
          meta={matchesMeta}
          clearFilters={resetFilters}
          accordionProps={{
            childrenTitle: t("activities:show.activityFilters"),
            filtersTitle: filtersCounter
              ? `${t("activities:show.moreFilters")} (${filtersCounter})`
              : t("activities:show.moreFilters"),
            filtersDisabled: filtersCounter === 0,
            allDisabled: areFiltersDisabled,
            activeState: !isNil(activeFiltersAccordionIndex) ? { [activeFiltersAccordionIndex]: true } : {},
            onActiveIndexChange: (index) => setActiveFiltersAccordionIndex(index)
          }}
          onFilterChange={updateFilter}
          onFiltersChange={setFilters}
          blockedList={blockedFiltersList}
          letRestoreAll={letRestoreAll}
          restoreAllTooltipContent={t("activities:show.matches.restoreAllTooltip")}
        >
          <div>
            <ActivityKeywords typesOpened={openedFilterTypes} onChangeTypeOpen={handleChangeTypeOpen} />
            <ActivityFilters
              meta={matchesMeta}
              schemaPropertiesNames={schemaPropertiesNames}
              onFilterChange={updateFilter}
            />
          </div>
        </FiltersPanel>
      )}
      {letRestoreAll && !filtersAreClear && (
        <Alert
          title={t("activities:show.matches.filtersChangedWarning")}
          action={{
            text: t("translation:filters.restoreAll"),
            icon: "history",
            kind: "text",
            onClick: () => resetFilters(matchesMeta.default_filters)
          }}
          small
        />
      )}
      <TableProperties
        isVisible={isTablePropertiesVisible}
        onCloseClick={toggleIsTablePropertiesVisible}
        defaultColumns={availableMatchesShortlistColumns}
        selectedColumns={selectedMatchesShortlistColumns}
        onColumnsChange={setSelectedMatchesShortlistColumns}
      />
    </>
  );
};
