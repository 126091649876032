import { Divider } from "PFComponents/divider";
import { Tile } from "PFComponents/tile";

import css from "./matches_contstraints.module.scss";
import { MatchesAvailability, MatchesFilters } from "./parts";

export const MatchesConstraints = () => (
  <Tile className={css.root} paddingVariant="lg" light>
    <MatchesAvailability />
    <Divider />
    <MatchesFilters />
  </Tile>
);
