import classNames from "classnames";
import spacing from "PFTheme/tokens/spacing";
import { HTMLAttributes } from "react";

import css from "./divs.module.scss";

type FlexDivProps = React.PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    direction?: "row" | "column";
    center?: boolean;
    gap?: keyof typeof spacing;
  }
>;

export const FlexDiv = ({
  children,
  className,
  direction = "row",
  center,
  gap,
  style = {},
  ...props
}: FlexDivProps) => (
  <div
    className={classNames(className, css.flex, {
      [css.center]: center
    })}
    {...props}
    style={{ ...style, ...(gap ? { gap: spacing[gap] } : {}), flexDirection: direction! }}
  >
    {children}
  </div>
);
