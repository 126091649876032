import isNil from "lodash/isNil";
import { SelectV2, SelectV2Props } from "PFComponents/select_v2";
import { FilterWithDefaults, Id } from "PFTypes";

import { letFilterRestore } from "../../use_filtered_collection";
import { formatValue } from "./filters.utils";
import { OptionOriginal, useFiltersOptions } from "./use_filters_options";

type SelectOneItemProps = SelectV2Props<OptionOriginal> & {
  filter: FilterWithDefaults;
  onChange: (id: Id | null, value?: OptionOriginal | null) => void;
  disabled?: boolean;
};

export const SelectOneItem = ({ filter, onChange, disabled, labelTooltip }: SelectOneItemProps) => {
  const letRestore = letFilterRestore(filter);
  const value = formatValue(filter.value);

  const optionsProps = useFiltersOptions(filter);

  const handleChange = (value) => onChange(value.id, value.original);
  const handleClear = () => onChange([]);

  return (
    <SelectV2<OptionOriginal>
      disabled={filter.disabled || disabled}
      label={filter.title}
      labelTooltip={labelTooltip}
      value={formatValue(filter.value)}
      onChange={handleChange}
      onRestore={letRestore ? () => onChange(filter.defaultValue as Id) : undefined}
      onClear={isNil(value) ? undefined : handleClear}
      {...optionsProps}
    />
  );
};
