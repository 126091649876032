import { useActivityTemplateType } from "PFApp/activities/hooks";
import { Button } from "PFComponents/button";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { Activity, Availability, Booking, PermissionRule } from "PFTypes";
import React, { useEffect } from "react";

import { useReassignActionApi } from "../../../booking_module_calendar/context/reassign_action_context_provider";

type ReassignButtonProps = {
  booking: Booking;
  activity: Activity | undefined;
  isEngagement: boolean;
  availability: Availability | undefined;
  sidePanelZIndex: number | undefined;
};

export const ReassignButton = ({
  booking,
  activity,
  isEngagement,
  availability,
  sidePanelZIndex
}: ReassignButtonProps) => {
  const { openSidePanel, closeSidePanel } = useReassignActionApi();

  const canReassignBookingsMatches = useIsCurrentUserPermittedTo(PermissionRule.ReassignBookingsMatches);
  const canReassignBookingsNamedResource = useIsCurrentUserPermittedTo(
    PermissionRule.ReassignBookingsNamedResource
  );
  const { isAudit, isRole } = useActivityTemplateType(activity);

  const canReassign = canReassignBookingsMatches || canReassignBookingsNamedResource;
  const isAuditRoleRelated = isAudit && isRole;
  const isNotPermitted =
    !canReassign || isAuditRoleRelated || (isEngagement && !canReassignBookingsNamedResource); // reassigning engagement bookings requires ReassignBookingsNamedResource permission as engagements don't have matches

  useEffect(() => {
    closeSidePanel();
    return () => {
      closeSidePanel();
    };
  }, [booking.id]);

  if (!activity || isNotPermitted) {
    return null;
  }

  return (
    <Button
      icon="reassign"
      onClick={() => {
        openSidePanel({
          activityName: booking.activity?.name,
          activity,
          sourceProfileId: booking.profile_id,
          booking,
          isEngagement,
          availability,
          sidePanelZIndex
        });
      }}
      kind="secondary"
    />
  );
};
